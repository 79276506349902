export class UserActivity {
    constructor(

        public userid: string= '',
         public activity: string= '',
        public pageurl: string= '',
        public prgid: string= '',
        public userip: string= '',
        public aLogDate: string= '',
      // public aLogDate:Date = new Date(),
    ) {}
}


