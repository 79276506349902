export class Employee {

    constructor(

        public empid: string = '',
        public prefix: string = '',


        public firstname: string = '',
        public middlename: string = '',
        public lastname: string = '',
        public gender: string = '',
        public maritalstatus: string = '',
        public dob: Date = new Date(),
        public doj: Date = new Date(),
        public pancard: string = '',
        public aadharnumber: string = '',
        public aadharname: string = '',

        public passportno: string = '',
        public bloodgroup: string = '',
        public hobby: string = '',
        public address: string = '',
        public state: string = '',
        public district: string = '',
        public city: string = '',
        public pincode: number = 0,
        public mobile: string = '',
        public altmobile: string = '',
        public email: string = '',
        public offemail: string = '',
        public offid: string = '',
        public offcity: string = '',
        public jobsince: string = '',
        public emptype: string = '',
        public grosssalary: number = 0,
        public salcreator: string = '',
        public salapproval: string = '',
        public paytype: string = '',
        public bankname: string = '',
        public branch: string = '',
        public accno: string = '',
        public neftcode: string = '',
        public branchadd: string = '',
        public empphoto: string = '',
        public createdby: string = '',
        public createdon: string = '',
        public emp_code: string = '',
        public pifassociate: string = '',
        public status: string = '',

        public dateofresignation: Date = new Date(),
        public exitformalitiescompleted: string = '',
        public reasonforleaving: string = '',
        public finalnoticeperioddate: Date = new Date(),

        public beneficiaryid: number = 0,
        public updatedby: string = '',
        public updatedon: Date = new Date(),
        public emptypeweekoff: string = '',
        ) { }
}
